import { Layout, Table, Button, Image, Space, Modal } from 'antd';
import { useGetVectorsQuery } from '../features/api/apiSlice'
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

export default function Vectors(props) {
  const { projectId } = useParams();
	const { setVectorsSelected } = props;
  
  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetVectorsQuery(projectId);

  return (
<Layout>
  <Table
    style={{ width: '100%' }}
    loading={isLoading}
    rowSelection={{
      type: 'checkbox',
      onChange: (keys, rows) => {
        setVectorsSelected(keys.map(k => data.find(s => s.drawing_id == k)));
      }
    }}
    columns={[{
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      render: (color) => (<div style={{ width: 10, height: 10, backgroundColor: color }} />),
    }, {
      title: 'Survey',
      key: 'survey_name',
			render: (name, record) => `${dayjs(record.created_at).format('DD MM YYYY')} ${record.name}` || '<UNSORTED>',
    }, {
      title: 'Drawing',
      dataIndex: 'name',
      key: 'name',
    }, {
      title: 'Total length',
      dataIndex: 'total_length',
      key: 'total_length',
			align: 'right',
			render: l => `${Math.round(l)} m`,
    }, {
      title: 'Latest Author',
      // dataIndex: 'total_length',
      // key: 'total_length',
			// align: 'right',
			render: (_, record) => `${record.latestBy.email}`,
    }, {
      title: 'Latest Date',
      dataIndex: 'latest_at',
      key: 'latestDate',
      render: latest_at => dayjs(latest_at).format('DD MMM YYYY'),
      filters: isLoading 
        ? []
        : [...new Set(data.map(({ latest_at }) => dayjs(latest_at).format('DD MMM YYYY')))]
            .map(val => ({ text: val, value: val })),
      onFilter: (value, record) => value == dayjs(record.latest_at).format('DD MMM YYYY'),
    }]}
    dataSource={data} 
  />
</Layout>
)}