import { Layout, Table, Col, Row, Button, Space, Modal } from 'antd';
import { useGetProjectsQuery } from '../features/api/apiSlice';
import { useState } from 'react';
import CreateProjectForm from './ProjectCreate';
import { useDeleteProjectMutation } from '../features/api/apiSlice';
import { ExclamationCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';

const { Header, Content } = Layout;

const { confirm } = Modal;

export default function Projects() {
  const [ openCreateProjectForm, setOpenCreateProjectForm ] = useState(false);
  
  const [ deleteProject ] = useDeleteProjectMutation();
  
  const {
    data: projects,
    isLoading
  } = useGetProjectsQuery();

  const showDeleteConfirm = projectId => {
    confirm({
      title: 'Are you sure delete project?',
      icon: <ExclamationCircleFilled />,
      content: 'You cannot undo this action',
      okText: 'Confirm',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          const response = await deleteProject(projectId).unwrap();

          console.log(response);
        } catch (e) {
          console.error(e)
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    })
  }

  return (
<Layout style={{ minHeight: '100vh', marginLeft: 200 }}>
  <Header style={{ display: 'inline-table' }}>
    <Row style={{ height: '100%' }}>
      <Col span={12}>
        {/* <Checkbox>Own projects</Checkbox> */}
      </Col>
      <Col style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} span={12}>
        <Button 
          type="primary"
          onClick={() => setOpenCreateProjectForm(true)} 
        >
          New Project
        </Button>
      </Col>
    </Row>
  </Header>
  <Content style={{ backgroundColor: 'white' }}>
    <Table
      loading={isLoading}
      columns={[{
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (title, record) => <a href={`/projects/${record.id}/map`}>{title}</a>,
      }, {
        title: 'Managed By',
        dataIndex: 'managed_by',
        key: 'managed_by',
        render: (managed_by, record) => record.email,
      }, {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      }, {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (created_at) => dayjs(created_at).format('MMM DD YYYY @ HH:mm'),
      }, {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <Button onClick={() => showDeleteConfirm(record.id)} danger>Delete</Button>
          </Space>
        ),
      },
    ]}
      dataSource={projects} 
    />
  </Content>
  <CreateProjectForm 
    open={openCreateProjectForm} 
    setOpen={setOpenCreateProjectForm}
  />
</Layout>
)}