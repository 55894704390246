import { useNavigate } from 'react-router-dom';
import { Modal, Form, Input, Upload } from "antd";
import { usePostProjectMutation } from '../features/api/apiSlice'
import { InboxOutlined } from '@ant-design/icons';

export default function CreateProjectForm (props) {
  const { open, setOpen } = props;
  const [ postProject, { isLoading }] = usePostProjectMutation()
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const handleCreate = () => {
    form
      .validateFields()
      .then(async values => {
        const { title, description, dragger } = values;
        const zip = Array.isArray(dragger) ? dragger[0] : {};
        form.resetFields();

        const data = await postProject({ title, description, zip: zip?.response?.zip }).unwrap();
        
        setOpen(false);
        navigate(`/projects/${data.id}/map`)
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFileRemove = (file) => {
    console.log(file.response)
  }

  return (
    <Modal
      open={open}
      title="New project"
      okText="Create"
      onCancel={() => {
        form.resetFields();
        setOpen(false);
      }}
      onOk={handleCreate}
      okButtonProps={{
        loading: isLoading,
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Title"
          name="title"
          rules={[{ 
            required: true, 
            message: "Please input the title of a new project" 
          }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input type="textarea" />
        </Form.Item>
        <Form.Item rules={[{required: true}]} pattern="[0-9]{1-10}" name="epsg" label="EPSG">
            <Input placeholder="e.g. '28992' for RD New / '3857' for Web Mercator"/>
        </Form.Item>
        <Form.Item name="filetype" label="File Type">
            <select defaultValue="coordinatesDWG">
                <option value="coordinatesDWG">True Coordinates DWG</option>
                <option value="geoDWG">Geo-located DWG</option>
                <option value="spapeZIP">Shape ZIP</option>
            </select>
        </Form.Item>
        <Form.Item label="Drawings">
            <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
            <Upload.Dragger maxCount={1} onRemove={onFileRemove} accept='.dwg,.zip' name="file" action={`${process.env.REACT_APP_API_URI}/uploads`}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag zip archive or dwg file to this area to upload</p>
            <p className="ant-upload-hint">Archive must contain .shp, .shx, and .dbf files for every layer.</p>
          </Upload.Dragger>
        </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};